import { Box } from '@mui/material';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
export interface bannerData {
  id?: number;
  isActive?: boolean;
  url?: string | undefined;
  index: number;
  image: {
    url: string;
    width: number;
    height: number;
  };
}
const HomeSlider = ({ homePageBanner }: any) => {
  const [bannerImage, setBannerImage] = useState<bannerData[]>([]);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const sortedBannerImage = homePageBanner || [];
        sortedBannerImage.sort((a: any, b: any) => a.index - b.index);
        setBannerImage(sortedBannerImage);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };
    fetchBanner();
  }, []);

  return (
    <Box className="home-slider">
      <Swiper
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          el: '.swiper-custom-pagination',
          clickable: true,
        }}
        speed={1000}
        modules={[Pagination, Autoplay]}
      >
        {bannerImage &&
          bannerImage?.map((res, i) => {
            const sliderImage = res && res.image && res.image.url;
            const imageWidth = res?.image?.width || 1170;
            const imageHeight = res?.image?.height || 530;
            const imageUrl = process.env.API_BASE_URL + sliderImage;
            const navigationURL = res.url || '';
            return (
              <React.Fragment key={res.id}>
                <SwiperSlide key={res.id}>
                  <Link href={navigationURL}>
                    <Image
                      loading="lazy"
                      key={i}
                      src={imageUrl}
                      alt="slider1"
                      width={imageWidth}
                      height={imageHeight}
                    />
                  </Link>
                </SwiperSlide>
              </React.Fragment>
            );
          })}
      </Swiper>
      <div className="swiper-custom-pagination" />
    </Box>
  );
};

export default HomeSlider;
